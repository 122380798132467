import { Controller } from "@hotwired/stimulus"
// import { isIosApp } from "helpers/platform_helpers"
// import { getCookie } from "lib/cookie"
// import { request } from "helpers"

export default class extends Controller {
  initialize() {
    // sendBeacon(this.element.action, this.formData)
    this.element.remove()
  }

  get formData() {
    const formData = new FormData(this.element)
    formData.set("authenticity_token", this.csrfToken)
    formData.set("from_beacon", true)
    return formData
  }

  get csrfToken() {
    const csrfParam = document.head.querySelector("meta[name=csrf-param]")?.content
    return csrfParam ? getCookie(csrfParam) : undefined
  }
}

// const sendBeacon = navigator.sendBeacon && !isIosApp
//   ? (url, body) => navigator.sendBeacon(url, body)
//   : (url, body) => request.post(url, { body });
