import { Controller } from "@hotwired/stimulus"
import $ from "jquery";

export default class extends Controller {
  static values = { url: String }

  connect () {
    this.bind_tabs();
  }

  initialize() {
    this.tabs = null;
  }

  bind_tabs() {
    this.tabs = $(this.menuItem).tab({
      cache   : false,
      context : this.element,
      auto    : true,
      path    : this.urlValue
    })
  }

  get menuItem() {
    return this.element.querySelectorAll('.menu .item');
  }

  disconnect() {
  }
};
