let base_diameter = 64;
let pie_chart = class extends HTMLElement {
    static get observedAttributes() {
      return ["percent", "type"]
    }
    constructor() {
      super(...arguments);
      this.render()
    }
    attributeChangedCallback() {
      this.render()
    }
    get percent() {
      let e = this.getAttribute("percent");
      return parseInt(e || 0, 10)
    }
    render() {
      switch (this.diameter = base_diameter,
        this.radius = this.diameter / 2,
        this.center = this.radius,
        this.getAttribute("type")) {
        case "donut":
          this.strokeWidth = this.radius * .3,
            this.radius = this.radius - this.strokeWidth / 2;
          break;
        default:
          this.strokeWidth = this.diameter
      }
      this.innerHTML = this.generateHTML()
    }
    generatePieCSS() {
      return `stroke-width: ${this.strokeWidth};`
    }
    generateSliceCSS() {
      let e = Math.PI * 2 * this.radius
        , n = this.percent * (e / 100);
      return this.generatePieCSS() + `stroke-dasharray: ${n} ${e};`
    }
    generateHTML() {
      return `
      <svg viewBox="0 0 ${this.diameter} ${this.diameter}">
        <circle class="pie" r="${this.radius}" cx="${this.center}" cy="${this.center}" style="${this.generatePieCSS()}" />
        <circle class="slice" r="${this.radius}" cx="${this.center}" cy="${this.center}" style="${this.generateSliceCSS()}" />
      </svg>`
    }
  }
;
window.customElements.define("bc-pie-chart", pie_chart);
