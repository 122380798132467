import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["card"];
  static values = { url: String };

  connect() {
  }

  initialize() {
    // this.isOpen = false;
  }

  // toggle() {
  //   if (this.isOpen) {
  //     this.hide();
  //   } else {
  //     this.show();
  //   }
  // }

  show() {
    if (this.hasCardTarget) {
      this.cardTarget.classList.remove("hidden");

    } else {
      fetch(this.urlValue)
        .then((r) => r.text())
        .then((html) => {
          const fragment = document
            .createRange()
            .createContextualFragment(html);

          this.element.appendChild(fragment);
        });
    }
    // this.isOpen = true;
  }

  hide() {
    if (this.hasCardTarget) {
      this.cardTarget.classList.add("hidden");
      // this.isOpen = false;
    }
  }

  disconnect() {
    if (this.hasCardTarget) {
      this.cardTarget.remove();
    }
  }
}
