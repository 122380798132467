import { Controller } from "@hotwired/stimulus"

// action: 'click->url-updater#updateUrl', url_updater_param_name: 'project_id', url_updater_param_value: project.id
export default class extends Controller {
  static values = {
    paramName: String,
    paramValue: String
  }

  updateUrl(event) {
    // event.preventDefault(); // Prevent default link behavior

    const link = event.target;
    let url = new URL(link.href, window.location.origin); // Use URL object for robust URL manipulation

    //get parameters from the data attributes
    const paramName = link.dataset.urlUpdaterParamName;
    const paramValue = link.dataset.urlUpdaterParamValue;

    url.searchParams.set(paramName, paramValue);

    // Replace the current URL in the browser history
    window.history.pushState({}, '', url);

    // Optional: Dispatch a custom event if you need to trigger other actions
    this.dispatch('url-updated', { detail: { url: url.toString() } });

    // If you want to actually navigate:
    // window.location.href = url.toString();
  }
}
