$ = require 'jquery'

greenPOS = window.greenPOS or {}
greenPOS.helpers = greenPOS.helpers or {}
greenPOS.js_loaded = false


greenPOS.init_fn = ->
  return if greenPOS.js_loaded

  console.log('js-plugins:init_fn')

  greenPOS.js_loaded = true

  greenPOS.initSidebar()
  greenPOS.initAccordion()
#  greenPOS.initTooltip()
  greenPOS.initRating()
#  greenPOS.initDateRangePicker()
#  greenPOS.initDateRangePickerWithClass()
  greenPOS.initDateRangeInputHandler()
  greenPOS.trimEmptyDivs()



greenPOS.trimEmptyDivs = ->
  document.querySelectorAll('.js-trim')
    .forEach((e)=> e.innerHTML = e.innerHTML.trim())

greenPOS.initAccordion = ->
  $('.ui.accordion').accordion({
    selector: {
      trigger: '.title'
    }
  })


greenPOS.initRating = ->
  $('.js-rating-readonly').rating({interactive: false})

  $('.js-candidate-rating').rating({
    interactive: true,
    onRate: (v)->
      candidate_id = $(this).data('candidateId')
      if candidate_id == "" or candidate_id is null
        return

      $.ajax
        url: "/hr/candidates/#{candidate_id}/change_rating?rating=" + encodeURIComponent(v)
        dataType: 'json'
        type: 'PUT'
        error: ->
          console.error("Rating Failed!")
        success: (res)->
          console.log("Rating Scucceded")
  })


#greenPOS.initTooltip = ->
#  $('.ui.tooltip').popup({
#    hoverable: true
#  })


greenPOS.initSidebar = ->
  handler =
    reset_active_item: ->
      $('#menuwrapper').find('.active').removeClass('active')

    close_active_menu: ->
      handler.fade_in_page()

      $('#menuwrapper').find('.open')
        .addClass('an-fadeOut')

      setTimeout ->
        $('#menuwrapper').find('.open.an-fadeOut')
          .removeClass('open an-fadeIn an-fadeOut')
      , 500

    fade_out_page: ->
      $('body').addClass('sidebar-is-open')

    fade_in_page: ->
      $('body').removeClass('sidebar-is-open')

    item_click: (e)->
      $item = $(@)
      $menu = $item.closest('li').find('ul')
      is_open = $menu.hasClass('open')

      handler.reset_active_item()
      handler.close_active_menu()

      if $menu.length and not is_open
        handler.fade_out_page()

        $menu.addClass('open animated an-fadeIn')
        $item.addClass('active')

      e.stopPropagation()
      e.preventDefault()


    document_click: (e)->
      if not $(e.target).closest('#menuwrapper').length
        handler.close_active_menu()
        handler.reset_active_item()


  $('#menuwrapper > ul > li > a').not('.noflyout')
    .on('click', handler.item_click)

  $('.js-toggle-sidebar').on 'click', (e)->
    e.preventDefault()
    $('#menuwrapper').toggle()


  $(document).on('click.me', handler.document_click)



greenPOS.initDateRangeInputHandler = ->
  apply_datetime_picker = ($el)->
    $el.daterangepicker({
      singleDatePicker: true,
      autoApply: true,
      autoUpdateInput: true,
      timePicker: true,
      timePickerIncrement: 10,
      locale: {
        format: 'DD/MM/YYYY h:mm A',
        applyLabel: 'تحديد',
        cancelLabel: 'إغلاق',
      }
    })
    $el


  $(document).on 'click', '.simple-datetime-picker', (e)->
    apply_datetime_picker($(this)).data('daterangepicker').show()



greenPOS.initDateRangePicker = ->
  $field = $('#date-range-input')

  date_range_with_time = $field.data('time') is true
  date_format = if date_range_with_time then 'DD/MM/YYYY h:mm A' else 'DD/MM/YYYY'


  _format_date = (date, format = date_format)->
    date.format(format)


  startDate = if $field.data('startDate') then moment($field.data('startDate'), date_format).format(date_format) else moment().startOf('day')
  endDate = if $field.data('endDate') then moment($field.data('endDate'), date_format).format(date_format) else moment().endOf('day')

  custom_ranges = $field.data('ranges') or {}


  ranges = {
    'اليوم': [
      moment().startOf('day'),
      moment().endOf('day')
    ],
    'أمس': [
      moment().subtract(1, 'day').startOf('day'),
      moment().subtract(1, 'day').endOf('day')
    ],
    'آخر 7 أيام': [
      moment().subtract(6, 'days').startOf('day'),
      moment().endOf('day')
    ],
    'الشهر الحالي': [
      moment().startOf('month').startOf('day'),
      moment().endOf('month').endOf('day')
    ],
    'الشهر السابق': [
      moment().subtract(1, 'month').startOf('month').startOf('day'),
      moment().subtract(1, 'month').endOf('month').endOf('day'),
    ],
    'الربع الأول': [
      moment().startOf('year').startOf('day'),
      moment().month(2).endOf('month').endOf('day')
    ],
    'الربع الثاني': [
      moment().month(3).startOf('month').startOf('day'),
      moment().month(5).endOf('month').endOf('day')
    ],
    'الربع الثالث': [
      moment().month(6).startOf('month').startOf('day'),
      moment().month(8).endOf('month').endOf('day')
    ],
    'الربع الرابع': [
      moment().month(9).startOf('month').startOf('day'),
      moment().month(11).endOf('month').endOf('day')
    ],
    'السنة الحالية': [
      moment().startOf('year').startOf('day'),
      moment().endOf('year').endOf('day')
    ],
    'السنة السابقة': [
      moment().subtract(1, 'year').startOf('year').startOf('day'),
      moment().subtract(1, 'year').endOf('year').endOf('day')
    ],
  }

  for range, dates of custom_ranges
    ranges[range] = dates.map (date)=> moment(date).format(date_format)

#  console.log('ranges,', ranges)


  daterange_picker_template = '<div class="daterangepicker dropdown-menu">' +
    '<div class="ranges">' +
        '<div class="range_inputs">' +
            '<button class="applyBtn" disabled="disabled" type="button"></button> ' +
            '<button class="cancelBtn" type="button"></button>' +
        '</div>' +
    '</div>' +

    '<div class="calendar left">' +
        '<div class="daterangepicker_input ui input field">' +
          '<input class="input-mini js-disabled" type="text" name="daterangepicker_start" value="" />' +
          '<i class="icon calendar"></i>' +
          '<i class="icon time time-toggle js-time-toggle"></i>' +
          '<div class="calendar-time">' +
            '<div></div>' +
            '<i class="icon time"></i>' +
          '</div>' +
        '</div>' +
        '<div class="calendar-table"></div>' +
    '</div>' +

    '<div class="calendar right">' +
        '<div class="daterangepicker_input ui input field">' +
          '<input class="input-mini js-disabled" type="text" name="daterangepicker_end" value="" />' +
          '<i class="icon calendar"></i>' +
          '<i class="icon time time-toggle js-time-toggle"></i>' +
          '<div class="calendar-time">' +
            '<div></div>' +
            '<i class="icon time"></i>' +
          '</div>' +
        '</div>' +
        '<div class="calendar-table"></div>' +
    '</div>' +


  '</div>'


  callback =  (start, end)->
    $el = $('#date-range-input span.date-area')
    formatted_date = _format_date(start, date_format) + ' - ' + _format_date(end, date_format)

    $el.html(formatted_date) if $el.length
    $('input[name="date_range"]').val(formatted_date)


  $('#date-range-input').daterangepicker({
    template: daterange_picker_template,
    autoApply: true,
    timePicker: date_range_with_time,
    timePickerIncrement: 10,
    opens: 'left',
    locale: {
      direction: 'rtl',
      format: date_format,
      applyLabel: 'تحديد',
      cancelLabel: 'إغلاق',
      fromLabel: 'من',
      toLabel: 'إلى',
      customRangeLabel: 'تحديد فترة',
      firstDay: 6
    },
    startDate: startDate,
    endDate: endDate,
    ranges: ranges
  }, callback)

  $('.js-time-toggle').one 'click', (e)->
    $('.daterangepicker_input').css('height', 'auto')
    $('.js-time-toggle').remove()




greenPOS.initDateRangePickerWithClass = ->
  $fields = $('.js-daterange-picker')

  $fields.each ->
    $field = $(this)

    date_range_with_time = $field.data('time') is true
    date_format = if date_range_with_time
      'DD/MM/YYYY h:mm A'
    else
      'DD/MM/YYYY'

    _format_date = (date, format = date_format)-> date.format(format)

    startDate = if $field.data('startDate')
      moment($field.data('startDate'), date_format).format(date_format)
    else
      moment().startOf('day')
    endDate = if $field.data('endDate')
      moment($field.data('endDate'), date_format).format(date_format)
    else
    moment().endOf('day')


    daterange_picker_template = '<div class="daterangepicker dropdown-menu">' +
      '<div class="ranges">' +
          '<div class="range_inputs">' +
              '<button class="applyBtn" disabled="disabled" type="button"></button> ' +
              '<button class="cancelBtn" type="button"></button>' +
          '</div>' +
      '</div>' +

      '<div class="calendar left">' +
          '<div class="daterangepicker_input ui input field">' +
            '<input class="input-mini js-disabled" type="text" name="daterangepicker_start" value="" />' +
            '<i class="icon calendar"></i>' +
            '<i class="icon time time-toggle js-time-toggle"></i>' +
            '<div class="calendar-time">' +
              '<div></div>' +
              '<i class="icon time"></i>' +
            '</div>' +
          '</div>' +
          '<div class="calendar-table"></div>' +
      '</div>' +

      '<div class="calendar right">' +
          '<div class="daterangepicker_input ui input field">' +
            '<input class="input-mini js-disabled" type="text" name="daterangepicker_end" value="" />' +
            '<i class="icon calendar"></i>' +
            '<i class="icon time time-toggle js-time-toggle"></i>' +
            '<div class="calendar-time">' +
              '<div></div>' +
              '<i class="icon time"></i>' +
            '</div>' +
          '</div>' +
          '<div class="calendar-table"></div>' +
      '</div>' +

    '</div>'


    $field.daterangepicker({
      template: daterange_picker_template,
      autoApply: true,
      timePicker: date_range_with_time,
      timePickerIncrement: 10,
      opens: 'left',
      locale: {
        direction: 'rtl',
        format: date_format,
        applyLabel: 'تحديد',
        cancelLabel: 'إغلاق',
        fromLabel: 'من',
        toLabel: 'إلى',
        customRangeLabel: 'تحديد فترة',
        firstDay: 6
      },
      startDate: startDate,
      endDate: endDate,
      ranges: {
        'اليوم': [
          moment().startOf('day'),
          moment().endOf('day')
        ],
        'أمس': [
          moment().subtract(1, 'day').startOf('day'),
          moment().subtract(1, 'day').endOf('day')
        ],
        'آخر 7 أيام': [
          moment().subtract(6, 'days').startOf('day'),
          moment().endOf('day')
        ],
        'الشهر الحالي': [
          moment().startOf('month').startOf('day'),
          moment().endOf('day')
        ],
        'الشهر السابق': [
          moment().subtract(1, 'month').startOf('month').startOf('day'),
          moment().subtract(1, 'month').endOf('month').endOf('day')
        ],
        'السنة الحالية': [
          moment().startOf('year').startOf('day'),
          moment().endOf('day')
        ],
        'السنة السابقة': [
          moment().startOf('year').startOf('day').subtract(1, 'year'),
          moment().startOf('year').startOf('day')
        ]
      }
    })




#$(document).ready(greenPOS.init_fn)
document.addEventListener('turbo:load', greenPOS.init_fn)
