import { Controller } from "@hotwired/stimulus"
import $ from "jquery";

export default class extends Controller {
  connect () {
    this.bind_tooltip();
  }

  initialize() {
  }

  bind_tooltip() {
    $(this.element).popup({
      hoverable: true
    });
  }

  disconnect() {
  }
};
