import { Controller } from "@hotwired/stimulus";
import { cancel, debounce, resetInput } from "javascripts/helpers"

export default class extends Controller {
  static targets = [ "cancel", "requiredInput", "submit" ]

  initialize() {
    this.debouncedSubmit = debounce(this.debouncedSubmit.bind(this), 300)
  }

  disconnect() {
    this.enableSubmits()
    this.element.toggleAttribute("data-submitting", false)
  }

  submit(event) {
    const form = event.target.form || event.target.closest("form")
    if (form) form.requestSubmit()
  }

  debouncedSubmit(event) {
    this.submit(event)
  }

  submitByKeyboard(event) {
    const commandEnterPressed = event.key == "Enter" && (event.metaKey || event.ctrlKey)
    if (!commandEnterPressed) return

    const form = event.target.form || event.target.closest("form")
    if (!form) return

    event.preventDefault()
    form.requestSubmit()
  }

  preventSubmitByKeyboard(event) {
    if (event.key == "Enter") event.preventDefault()
  }

  cancelByKeyboard(event) {
    if (event.key == "Escape" && this.hasCancelTarget) this.cancelTarget.click()
  }

  resetByKeyboard({ key, target }) {
    if (key == "Escape") target.value ? resetInput(target) : target.form?.reset()
  }

  // disableSubmitIfTrixAttachmentsUploading(event) {
  //   const { hasTrixAttachmentsUploading } = this
  //   this.submitTargets.forEach(submitTarget => submitTarget.disabled = hasTrixAttachmentsUploading)
  // }

  validateForm(event) {
    const incompleteRequiredField = this.firstIncompleteRequiredField
    // if (incompleteRequiredField || this.isSubmitDisabled || this.hasTrixAttachmentsUploading) {
    if (incompleteRequiredField || this.isSubmitDisabled) {
      incompleteRequiredField?.focus()
      event.preventDefault()
      event.stopImmediatePropagation()
    }
  }

  disableSubmits() {
    this.submitTargets.forEach(submitTarget => submitTarget.disabled = true)
  }

  enableSubmits() {
    this.submitTargets.forEach(submitTarget => submitTarget.disabled = false)
  }

  submitStart(event) {
    const form = this.element
    if (form) {
      form.toggleAttribute("data-submitting", true)
      this.disableSubmits()
    }
  }

  submitEnd(event) {
    const form = this.element
    if (form) {
      form.toggleAttribute("data-submitting", false)
      this.enableSubmits()
    }
  }

  suppressValidationMessage(event) {
    cancel(event)
  }

  get firstIncompleteRequiredField() {
    return this.requiredInputTargets.find(isEmpty)
  }

  get isSubmitDisabled() {
    return this.submitTargets.some(target => target.disabled)
  }

  // get hasTrixAttachmentsUploading() {
  //   return this.trixAttachments.some(attachment => attachment.isPending())
  // }
  //
  // get trixAttachments() {
  //   return this.trixElements.flatMap(trix => trix.editor.getDocument().getAttachments())
  // }
  //
  // get trixElements() {
  //   return Array.from(this.element.querySelectorAll("trix-editor"))
  // }
}

function isEmpty(input) {
  const value = input.editor ? input.editor.getDocument() : input.value
  return value.toString().trim().length == 0
};
