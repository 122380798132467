import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "footer", "link" ]

  initialize() {
  }

  // Actions

  toggleFooter() {
    this.footerTarget.classList.toggle("d-none")
  }
};
