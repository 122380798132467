import { Controller } from "@hotwired/stimulus"
import $ from "jquery";

export default class extends Controller {
  connect () {
    this.element.classList.add("ltr");
    this.bind_datepicker();
  }

  bind_datepicker() {
    this.datepicker = $(this.element).datepicker({
      format: this.element.dataset.dateFormat || 'yyyy-mm-dd',
      weekStart: 6,
      todayBtn: false,
      todayHighlight: true,
      startView: this.element.dataset.startView || 0,
      language: 'ar',
      endDate: Infinity,
      startDate: -Infinity,
  }).on('changeDate', function(ev) {
      $(this).data('datepicker').hide();
    });
  }


  initialize() {
    this.dropdown = null;
  }

  disconnect() {
  }
};
