import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["wrapper", "sidebar", "content"];

  connect() {
    this.setupEventListeners();
  }

  setupEventListeners() {
    // Listen for the custom event
    document.addEventListener("sidebar:open", this.handleSidebarOpen.bind(this));

    this.wrapperTarget.addEventListener("click", (e) => {
      if (e.target.classList.contains("sidebar-details-backdrop")) {
        this.close();
      }
    });
  }

  handleSidebarOpen(event) {
    // console.log("Sidebar open event received:", event.detail);
    const {content, cssClass} = event.detail; // Extract content and CSS class from the event

    // Update the sidebar content
    this.contentTarget.innerHTML = content;

    // Add the dynamic CSS class
    if (cssClass) {
      // split the class string into an array
      const classes = cssClass.split(" ");
      // add each class to the element
      classes.forEach(className => {
        this.sidebarTarget.classList.add(className);
      });
    }

    // Open the sidebar
    this.open();
  }

  open() {
    this.element.classList.add("is-open"); // Add a class to open the sidebar
  }

  close() {
    this.element.classList.remove("is-open"); // Remove the class to close the sidebar
    this.contentTarget.innerHTML = ""; // Clear the content
  }
}
