import { Controller } from "@hotwired/stimulus"
import $ from "jquery";

export default class extends Controller {
  connect () {
    this.bind_dropdown();
  }

  initialize() {
  }

  bind_dropdown() {
    $('.menu-dropdown').dropdown();
  }

  disconnect() {
  }
};
