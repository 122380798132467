import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    msg: String,
    type: String,
    duration: Number
  };

  connect() {
    this.show()
  }

  show() {
    window.notify(this.msgValue, {
      layout: 'topCenter', //alert, success, error, warning, info
      type: this.type,
      timeout: this.durationValue || 4000,
      animation: {
        open: 'animated an-bounceIn', // Animate.css class names
        close: 'animated an-flipOutX', // Animate.css class names
        easing: 'swing', // unavailable - no need
        speed: 500 // unavailable - no need
      }
    })
  }

  get type() {
    return this.typeValue == 'notice' ? 'success' : 'error';
  }

  hide() {
    this.element.remove()
  }

  disconnect() {
  }
}
