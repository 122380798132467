sideBar =
  setContent: (content) ->
    document.querySelector('.sidebar-details-wrapper .sidebar-details .content')
      .innerHTML = content

  open: ->
    document.querySelector('.sidebar-details-wrapper').classList.add('is-open')

  close: ->
    document.querySelector('.sidebar-details-wrapper').classList.remove('is-open')
    this.setContent('')

greenPOS = window.greenPOS or {}
greenPOS.sideBar = sideBar

