Vue = require 'vue'
_includes = require 'lodash/includes'
import consumer from "channels/consumer";


NotificationsComponent =
#  el: '.notifications-component'
  data: ->
    notifications: []
    unread_count: 0
    upper_unread_count_limit: 9
    allowed_notifications: ['new_fulfillment', 'new_order', 'stock_transfer_request']


  created: ->
    @unread_count = window.recent_unseen_notifications_count or 0
    @fetchNewNotifications()

    consumer.subscriptions.create "NotificationsChannel",
      connected: ->
        console.log("connected to actioncable Yay!")

      disconnected: ->
#        console.log("disconnected")

      received: @received


  methods:
    received: (data)->
      return unless App.location_id is data.location_id
      return unless _includes(@allowed_notifications, data.reason)

      console.log 'NOTIFICATION::'
      console.table data

      @unread_count += 1
      @notifications.unshift(data)


    fetchNewNotifications: ->
      $.get('/notifications/recent.json')
        .then (data)=>
          @notifications = data


  computed:
    unread_count_text: ->
      if @unread_count > @upper_unread_count_limit
        "+#{@upper_unread_count_limit}"
      else
        @unread_count


el = document.querySelector '.notifications-component'
new Vue(NotificationsComponent).$mount(el) if el
