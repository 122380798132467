import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"
import Noty from 'noty';

// Connects to data-controller="noty"
export default class extends Controller {
  static values = {
    message: String,
    type: String,
    layout: String,
    autoClose: Boolean,
    duration: Number,
    url: String,
  }
  connect() {
    this.noty = new Noty({
      closeWith: ['click', 'button'],
      text: this.messageValue,
      type: this.type,
      layout: this.layout,
      timeout: this.timeout,
      theme: 'relax',
      callbacks: {
        onTemplate: function() {
          this.barDom.setAttribute('data-turbo-temporary', 'true');
        }
      }
    }).show();

    if (this.hasUrlValue) {
      console.log('url =>', this.urlValue);

      this.noty.on('onClick', ()=> {
        Turbo.visit(this.urlValue, { action: "advance" });
      })
    }
    this.element.remove();
  }

  get type() {
    return this.typeValue || 'alert';
  }

  get layout() {
    return this.layoutValue || 'topCenter';
  }

  get timeout() {
    if (this.hasAutoCloseValue && this.autoCloseValue === false) {
      return false;
    } else {
      return this.duration;
    }
  }

  get duration() {
    return this.hasDurationValue ? this.durationValue : 3500;
  }
}
