// sidebar-trigger-controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    cssClass: String,
  };

  openSidebar(event) {
    event.preventDefault();
    const url = event.currentTarget.href;

    fetch(url)
      .then(response => response.text())
      .then(html => {
        const sidebarEvent = new CustomEvent("sidebar:open", {
          detail: { content: html, cssClass: this.cssClassValue }
        });
        document.dispatchEvent(sidebarEvent); // Dispatch the event globally
      })
      .catch(error => console.error('Error fetching sidebar content:', error));
  }
}
